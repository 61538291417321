import React, { useState, useEffect } from "react";
import BookingInvoicingReportToolbarComponent from "../../components/invoicing-report/booking-invoicing-report-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingInvoicingReportFilterThunk,
  setBookingInvoicingReportFilters,
} from "../../redux/actions/invoicing-report-actions";
import { getStartOf } from "../../utils/dateUtils";
import { useNavigate } from "/src/hooks/navigation";

const InvoicingReportToolbar = ({ onRefresh }) => {
  const { navigate } = useNavigate();

  const [invoicesTotal, setInvoicesTotal] = useState(null);

  const filters = useSelector((state) => state.invoicingReport.bookingFilters);
  const filtersOptions = useSelector((state) => state.invoicingReport.bookingFiltersOption);
  const invoices = useSelector((state) => state.invoicingReport.invoices);
  const dispatch = useDispatch();

  const handleFilterChange = (field) => (newValues) => {
    dispatch(
      setBookingInvoicingReportFilters({
        ...filters,
        [field]: newValues,
      }),
    );
  };

  const getBookingInvoicesFilters = () => {
    const assets = JSON.parse(localStorage.getItem("bookingInvoiceAssets")) || [];
    const categories = JSON.parse(localStorage.getItem("bookingInvoiceCategories")) || [];
    const companies = JSON.parse(localStorage.getItem("bookingInvoiceClients")) || [];
    const status = JSON.parse(localStorage.getItem("bookingInvoiceStatus")) || [];

    dispatch(
      setBookingInvoicingReportFilters({
        assets,
        companies,
        categories,
        status,
        year: getStartOf("year").format("YYYY"),
      }),
    );
  };

  const handleOnChangePage = (selectedView) => {
    if (selectedView === "invoice") {
      navigate("/administrator/invoicing");
    } else if (selectedView === "booking") {
      navigate("/administrator/invoicing/bookings");
    }
  };

  useEffect(() => {
    dispatch(getBookingInvoicingReportFilterThunk());
    dispatch(
      setBookingInvoicingReportFilters({
        ...filters,
        year: getStartOf("year").format("YYYY"),
      }),
    );
    getBookingInvoicesFilters();
  }, []);

  useEffect(() => {
    setInvoicesTotal(invoices.balance);
  }, [invoices]);

  return (
    <BookingInvoicingReportToolbarComponent
      onRefresh={onRefresh}
      invoicesTotal={invoicesTotal}
      filters={filters}
      filtersOptions={filtersOptions}
      onFilterChange={handleFilterChange}
      onChangePage={handleOnChangePage}
    />
  );
};

export default InvoicingReportToolbar;
