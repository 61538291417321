import { Box, Link, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Image from "material-ui-image";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "@lingui/macro";
import LeftArrow from "../../../icons/left-arrow";
import RightArrow from "../../../icons/right-arrow";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    height: "75%",
    width: "800px",
    backgroundColor: theme.palette.background.paper,
    border: "transparent",
    padding: theme.spacing(4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    [theme.breakpoints.down("sm")]: {
      height: "60%",
      width: "100%",
    },
  },
  text: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  optionsContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  option: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  selectedOption: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  arrow: {
    position: "absolute",
    top: "calc(50% - 20px)",
    height: 35,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: 25,
    },
  },
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
}));

const SamplesModal = ({ images = [] }) => {
  const classes = useStyles(theme, images.length);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleChangeIndex = (newIndex) => {
    setSelectedIndex(newIndex);
  };

  const handleNextImage = () => {
    if (selectedIndex < images.length - 1) setSelectedIndex(selectedIndex + 1);
  };

  const handlePreviousImage = () => {
    if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isSelected = (index) => index === selectedIndex;

  const Content = ({ image }) => {
    return (
      <Image
        src={image.url}
        style={{ height: "100%", padding: "0", width: "100%" }}
        imageStyle={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
    );
  };

  return (
    <>
      <Typography variant="body1" className={classes.text}>
        <Link className={classes.link} onClick={handleOpen}>
          <Trans>Ver ejemplo de fotos</Trans>.
        </Link>
      </Typography>
      <Modal open={open} onClose={handleClose}>
        <Box display="flex" flexDirection="column" className={classes.modal}>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
          <Box>
            <Typography variant="h4" color="primary">
              <Trans>FOTOS</Trans>
            </Typography>
          </Box>
          <Box className={classes.optionsContainer}>
            {images.map((image, index) => (
              <Box
                key={image.title}
                onClick={() => handleChangeIndex(index)}
                className={`${classes.option} ${isSelected(index) ? classes.selectedOption : ""}`}
              >
                <Typography component="span" variant="button">
                  {image.title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box flexGrow={1} position="relative">
            <LeftArrow className={classes.arrow} style={{ left: "-25px" }} onClick={handlePreviousImage} />
            <Content image={images[selectedIndex]} />
            <RightArrow className={classes.arrow} style={{ right: "-25px" }} onClick={handleNextImage} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

SamplesModal.propTypes = {
  images: PropTypes.array,
};

export default SamplesModal;
