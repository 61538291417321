import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useLingui } from "@lingui/react";
import Typography from "@material-ui/core/Typography";
import { Trans, t } from "@lingui/macro";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import Image from "material-ui-image";
import Link from "@material-ui/core/Link";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import theme from "../../../theme";
import TextField from "../../text-field";
import InputFileUpload from "../../file-upload/input-file-upload";
import CloseIcon from "../../../icons/close-icon";
import StepButtons from "../../step-buttons";
import DecideDialog from "../../decide-dialog-owner";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    color: th.palette.text.primary,
    paddingLeft: th.spacing(2),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputs: {
    width: "217px",
  },
  selects: {
    display: "flex",
    flexDirection: "row",
  },
  attachmentIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    backgroundColor: th.palette.grayC4,
    cursor: "pointer",
    position: "relative",
  },
  attachmentImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: th.spacing(1 / 2),
    borderRadius: "5px",
    width: 80,
    height: 80,
    cursor: "pointer",
    position: "relative",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "22px",
    height: "22px",
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: th.palette.grayC4,
    zIndex: 999,
    left: "66px",
    top: "-8px",
    cursor: "pointer",
  },
  continueButton: {
    width: 190,
    height: 42,
    [th.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeRadioButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginRight: th.spacing(4),
  },
  typeRadioButtonSuccess: {
    color: th.palette.primary.main,
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: th.zIndex.drawer + 1,
    color: th.palette.white,
  },
}));

const Information = ({
  asset = {},
  status = "initial",
  submit = () => {},
  submitStatus = "initial",
  resetSubmitStatus,
  onDeleteAttachment,
}) => {
  const classes = useStyles(theme);

  const [values, setValues] = useState(asset);
  const [valuesHasChanged, setValuesHasChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] = useState("initial");
  const [mapUploadStatus, setMapUploadStatus] = useState("initial");

  const { i18n } = useLingui();

  const MAX_MB = 2;
  const hasAttachmentsChanged = asset.attachments !== values.attachments;

  const assetThumbAttachment =
    values.attachments &&
    values.attachments.length > 0 &&
    values.attachments.find((attachment) => attachment.meta.type === "asset_thumb");

  const assetThumbAttachmentUrl =
    (assetThumbAttachment &&
      values.attachments.find((attachment) => attachment.meta.type === "asset_thumb").file_url) ||
    values.thumb_url;

  const assetMapAttachment =
    values.attachments &&
    values.attachments.length > 0 &&
    values.attachments.find((attachment) => attachment.meta.type === "map");

  // Asset
  const handleStartUpload = () => setImageUploadStatus("loading");
  const handleErrorUpload = () => setImageUploadStatus("error");
  const handleUploadImage = () => {
    setImageUploadStatus("initial");
    submit(values);
  };

  // Map
  const handleStartMapUpload = () => setMapUploadStatus("loading");
  const handleErrorMapUpload = () => setMapUploadStatus("error");
  const handleUploadMap = () => {
    setMapUploadStatus("initial");
    submit(values);
  };

  const handleFinish = () => {
    submit(values);
  };

  const handleCancel = () => {
    setValues(asset);
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((v) => ({ ...v, [field]: target.value }));
    };

  useEffect(() => {
    if (asset) {
      setValues(asset);
    }
  }, [asset]);

  useEffect(() => {
    setValuesHasChanged(!(JSON.stringify(asset) === JSON.stringify(values)));
    if (hasAttachmentsChanged) {
      handleFinish();
    }
  }, [values]);

  return (
    <>
      <Box className={classes.container}>
        <DecideDialog
          open={openDialog}
          onAccept={handleFinish}
          title={<Trans>Existen cambios sin guardar</Trans>}
          desc={
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          }
          acceptText={<Trans>Guardar y continuar</Trans>}
          rejectText={<Trans>Continuar sin guardar</Trans>}
          onClose={() => setOpenDialog(false)}
        />
        <Backdrop
          className={classes.backdrop}
          open={
            status === "loading" ||
            submitStatus === "loading" ||
            imageUploadStatus === "loading" ||
            mapUploadStatus === "loading"
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={submitStatus === "error"} autoHideDuration={3000}>
          <MuiAlert elevation={6} variant="filled" severity="error">
            <Trans>Ha ocurrido un error. Por favor intente de nuevo.</Trans>
          </MuiAlert>
        </Snackbar>
        <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                multiline
                rows={6}
                description={`${i18n._(t`Descripción del centro comercial*`)}`}
                validators={["required"]}
                errorMessages={[""]}
                value={values?.description}
                onChange={handleInputChange("description")}
                labelClassNames={classes.label}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography variant="subtitle1" className={classes.label}>
              <Trans>Fotografía*</Trans>
            </Typography>
            <Box mb={1}>
              <Typography variant="body1">
                <Trans>Foto principal. Máx. 2mb</Trans>
              </Typography>
            </Box>
          </Box>
          {imageUploadStatus === "loading" && (
            <Box display="flex" alignItems="center" justifyContent="center" width={65} height={65} mb={3 / 2}>
              <CircularProgress />
            </Box>
          )}
          {assetThumbAttachmentUrl && imageUploadStatus !== "loading" && (
            <Box position="relative" width={80}>
              <Box onClick={() => onDeleteAttachment(assetThumbAttachment.id)} className={classes.closeIcon}>
                <CloseIcon width="12px" height="12px" />
              </Box>
              <Link href={assetThumbAttachmentUrl} target="_blank">
                <Box className={classes.attachmentImage}>
                  <Image
                    src={assetThumbAttachmentUrl}
                    style={{
                      height: "100%",
                      padding: "0",
                      width: "100%",
                    }}
                    imageStyle={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              </Link>
            </Box>
          )}
          {!assetThumbAttachmentUrl && imageUploadStatus !== "loading" && (
            <Box display="flex" flexDirection="row">
              <label className={classes.attachmentIcon} htmlFor="upload-asset">
                <InputFileUpload
                  id="upload-asset"
                  name="upload-asset"
                  maxSize={MAX_MB * 1024 * 1024} // 2MB
                  accept="image/jpg,image/jpeg,image/png"
                  style={{ display: "none" }}
                  onComplete={handleUploadImage}
                  onStart={handleStartUpload}
                  onError={handleErrorUpload}
                  model="asset"
                  modelId={asset.id}
                  meta={{ type: "asset_thumb" }}
                />
                <Box className={classes.pointer}>
                  <AddIcon />
                </Box>
              </label>
            </Box>
          )}

          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.label}>
              <Trans>Mapa del centro comercial</Trans>
            </Typography>
            <Box mb={1}>
              <Typography variant="body1">
                <Trans>Sube el mapa del centro comercial. Máx. 2mb</Trans>
              </Typography>
            </Box>
          </Box>
          {mapUploadStatus === "loading" && (
            <Box display="flex" alignItems="center" justifyContent="center" width={65} height={65} mb={3 / 2}>
              <CircularProgress />
            </Box>
          )}
          {assetMapAttachment && assetMapAttachment.file_url && mapUploadStatus !== "loading" && (
            <Box position="relative" width={80}>
              <Box onClick={() => onDeleteAttachment(assetMapAttachment.id)} className={classes.closeIcon}>
                <CloseIcon width="12px" height="12px" />
              </Box>
              <Link href={assetMapAttachment.file_url} target="_blank">
                <Box className={classes.attachmentImage}>
                  <Image
                    src={assetMapAttachment.file_url}
                    style={{
                      height: "100%",
                      padding: "0",
                      width: "100%",
                    }}
                    imageStyle={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              </Link>
            </Box>
          )}
          {!assetMapAttachment && mapUploadStatus !== "loading" && (
            <Box display="flex" flexDirection="row">
              <label className={classes.attachmentIcon} htmlFor="upload-map">
                <InputFileUpload
                  id="upload-map"
                  name="upload-map"
                  maxSize={MAX_MB * 1024 * 1024} // 2MB
                  accept="image/jpg,image/jpeg,image/png"
                  style={{ display: "none" }}
                  onStart={handleStartMapUpload}
                  onComplete={handleUploadMap}
                  onError={handleErrorMapUpload}
                  model="asset"
                  modelId={asset.id}
                  meta={{ type: "map" }}
                />
                <Box className={classes.pointer}>
                  <AddIcon />
                </Box>
              </label>
            </Box>
          )}
        </ValidatorForm>
      </Box>
      <StepButtons
        continueButtonLabel={<Trans>Guardar</Trans>}
        backButtonLabel={<Trans>Olvidar cambios</Trans>}
        handleContinue={handleFinish}
        handleBack={handleCancel}
        disableContinue={!valuesHasChanged}
        disableBack={!valuesHasChanged}
      />
    </>
  );
};

export default Information;
