import { Box, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import numbro from "numbro";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GoToPageIcon from "../../icons/go-to-page-icon";
import VisibilityOn from "../../icons/visibility-on-icon";
import VisibilityOff from "../../icons/visibility-off-icon";
// theme
import theme from "../../theme-admin";
import Table from "../table";
import TableFooter from "../table-footer";
import SpacesToolbar from "../../containers/spaces-owner/space-toolbar";
import TableDataDownload from "../table-data-download";
import Link from "/src/components/link";
import { getSpaceSlug } from "../../utils";
import ImportModal from "../import-modal";
import api from "../../api";

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "absolute",
    right: 50,
    bottom: 50,
  },
  notification: {
    color: theme.palette.primary.main,
  },
  filters: {
    height: 25,
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    marginRight: theme.spacing(1),
    borderRadius: 4,
  },
  visbilityButton: {
    opacity: 0.4,
  },
}));

function SpacesOwner({
  spaces,
  status,
  onTableChange,
  publishSpace,
  downloadTableData,
  visibilityStatus = {},
  onClickAdd,
  onRowClick,
  onEyeClick = () => {},
  pagination,
  onChangePage,
  canEdit,
}) {
  /*  
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  */
  const classes = useStyles(theme);
  const [openImportSpacesModal, setOpenImportSpacesModal] = useState(false);
  const [importStatus, setImportStatus] = useState("initial");
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const importSpaces = async (file, assetId) => {
    setImportStatus("loading");
    const res = await api.importSpaces({ file, assetId });
    if (res.status === 200) {
      setImportStatus("success");
    } else {
      setImportStatus("error");
    }
  };

  const downloadSpacesImportSample = async () => {
    await api.getSpacesImportSample();
  };

  const handleRowClick = (dataIndex) => {
    if (canEdit) {
      const space = spaces.find((s) => s.id === dataIndex[0]);
      onRowClick(space);
    }
  };

  useEffect(() => {
    onTableChange("changePage", { page: 0 });
  }, []);

  const { i18n } = useLingui();

  const handleDownloadTable = () => {
    setOpenDownloadModal(true);
    return false;
  };

  const handleTableData = () => {
    const data = spaces.map((s) => {
      return {
        id: s.id,
        title: s.title,
        asset: s.asset.name,
        city: s.asset.city.name,
        location: `${i18n._(t`${s.type.name}`)}`,
        type: `${i18n._(t`${s.subtype.name}`)}`,
        size: `${s.size}m2` || 0,
        price: numbro(s.display_price || 0).formatCurrency(),
        searchable: s.searchable,
      };
    });

    return data;
  };

  const columns = [
    { label: "ID", name: "id" },
    {
      label: `${i18n._(t`Espacio`)}`,
      name: "title",
      options: {
        customBodyRenderLite: (dataIndex) => <Box title={spaces[dataIndex].title}>{spaces[dataIndex].title}</Box>,
        setCellProps: (value) => {
          return {
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 140,
              textAlign: "left",
              paddingLeft: "10px",
              paddingRight: "10px",
            },
          };
        },
      },
    },
    {
      label: `${i18n._(t`Centro Comercial`)}`,
      name: "asset",
    },
    {
      label: `${i18n._(t`Ciudad`)}`,
      name: "city",
    },
    {
      label: `${i18n._(t`Tipo`)}`,
      name: "type",
    },
    {
      label: `${i18n._(t`Ubicación`)}`,
      name: "location",
    },
    {
      label: `${i18n._(t`Tamaño`)}`,
      name: "size",
    },
    {
      label: `${i18n._(t`Precio`)}`,
      name: "price",
    } /* ,
    { label: "Perf.", name: "", options: {
      customBodyRenderLite: (dataIndex) => (
        <BarChartSharp className={classes.notification} />
      ),
      setCellProps: value => ({
        style: {
          width: 50
        }
      })
    }}, */,
  ];

  const addActionColumns = () => {
    if (canEdit) {
      columns.push({
        label: `${i18n._(t`Acciones`)}`,
        name: "searchable",
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => (
            <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
              <Tooltip
                title={
                  spaces[dataIndex].searchable === 0 || spaces[dataIndex].searchable === false ? (
                    <Trans>Hacer visible</Trans>
                  ) : (
                    <Trans>Hacer invisible</Trans>
                  )
                }
              >
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    publishSpace(spaces[dataIndex].id, spaces[dataIndex].searchable);
                  }}
                  mr={1 / 2}
                >
                  <Box
                    className={`${visibilityStatus[spaces[dataIndex].id] === "loading" ? classes.visbilityButton : ""}`}
                  >
                    {spaces[dataIndex].searchable === 0 || spaces[dataIndex].searchable === false ? (
                      <VisibilityOff fill={theme.palette.black} />
                    ) : (
                      <VisibilityOn fill={theme.palette.black} />
                    )}
                  </Box>
                </Box>
              </Tooltip>
              <Box>
                <Tooltip title={<Trans>Abrir espacio en otra pestaña</Trans>}>
                  <a
                    href={`/space/${getSpaceSlug(spaces[dataIndex])}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <GoToPageIcon fill={theme.palette.black} />
                  </a>
                </Tooltip>
              </Box>
            </Box>
          ),
          setCellProps: () => ({
            style: {
              width: 50,
            },
          }),
        },
      });
    }
  };

  addActionColumns();

  return (
    <>
      {openImportSpacesModal && (
        <ImportModal
          open={openImportSpacesModal}
          importStatus={importStatus}
          title={i18n._(t`Importar espacios`)}
          onSubmit={(file, assetId) => importSpaces(file, assetId)}
          onDownloadSample={downloadSpacesImportSample}
          onClose={() => setOpenImportSpacesModal(false)}
          assetsSelect
        />
      )}

      <Box pl={2} pr={2} height="100%">
        <TableDataDownload
          open={openDownloadModal}
          onClose={() => setOpenDownloadModal(false)}
          onDownload={downloadTableData}
        />
        <Table
          data={handleTableData()}
          columns={columns}
          isLoading={status === "loading"}
          onDownload={handleDownloadTable}
          options={{
            onTableChange,
            onRowClick: handleRowClick,
            customToolbar: () => {
              return <SpacesToolbar />;
            },
            customFooter: () => {
              return (
                <TableFooter
                  pagination={pagination}
                  onChangePage={onChangePage}
                  primaryButton={i18n._(t`Crear nuevo`)}
                  secondaryButton={i18n._(t`Importar espacios`)}
                  onPrimaryButton={onClickAdd}
                  onSecondaryButton={() => setOpenImportSpacesModal(true)}
                  actionButtons={FooterButtons}
                />
              );
            },
          }}
        />
      </Box>
    </>
  );
}

SpacesOwner.propTypes = {
  spaces: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

const FooterButtons = () => {
  const classes = useStyles(theme);

  return (
    <Link to="/administrator/spaces/occupation/booking/spaces/all">
      <Button className={classes.filters} variant="contained">
        <Trans>Ocupación de espacios</Trans>
      </Button>
    </Link>
  );
};

export default SpacesOwner;
