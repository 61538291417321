import { t, Trans } from "@lingui/macro";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
// theme
import { useLingui } from "@lingui/react";
import { ValidatorForm } from "react-material-ui-form-validator";
import theme from "../../../theme-admin";
import TextField from "../../text-field";

const useStyles = makeStyles((th) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },

  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  inputRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white,
  },
}));

const MoreInformation = ({ data, status, onSave }) => {
  const [company, setCompany] = useState(data);
  const classes = useStyles({ ...theme });
  const { i18n } = useLingui();

  const handleSave = () => {
    const basicFields = { billing_address: company.billing_address };

    return onSave(basicFields);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
    handleSave();
  };

  useEffect(() => {
    setCompany(data);
  }, [data]);

  return (
    <Box className={classes.container}>
      <ValidatorForm className={classes.validatorContainer} onSubmit={() => {}}>
        <Box className={classes.inputRow}>
          {status.status === "loading" && status.type === "render-page" ? (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="150px">
              <CircularProgress size="48px" />
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  mt={4}
                  multiline
                  rows={6}
                  name="billing_address"
                  description={`${i18n._(t`Dirección de facturación`)}`}
                  errorMessages={[""]}
                  value={company.billing_address}
                  onChange={handleInputChange}
                  labelClassNames={classes.label}
                  fullWidth
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gridGap={3}
                  p={1}
                  // style={{ backgroundColor: theme.palette.white, width: "598px" }}
                  style={{ backgroundColor: theme.palette.white }}
                >
                  {status.status === "loading" && status.type === "save-form" && (
                    <Box display="flex" justifyContent="start" alignItems="start" width="100%" height="30px">
                      <CircularProgress size="25px" />
                    </Box>
                  )}
                  {status.status === "error" && (
                    <Box display="flex" justifyContent="start" alignItems="start" width="100%" height="30px">
                      <Typography variant="body1" style={{ color: theme.palette.error.main }}>
                        <Trans>Ha ocurrido un error</Trans>
                      </Typography>
                    </Box>
                  )}
                  {status.status === "success" && status.type === "save-form" && (
                    <Box display="flex" justifyContent="start" alignItems="start" width="100%" height="30px">
                      <Typography variant="body1" style={{ color: theme.palette.success.main }}>
                        <Trans>El cambio han sido guardado exitosamente.</Trans>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </ValidatorForm>
    </Box>
  );
};

export default MoreInformation;
