/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ValidatorForm } from "react-material-ui-form-validator";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBox from "../custom-checkbox/custom-checkbox-alternative-2";
import CalendarInOutInput from "../calendar-in-out-input";
import theme from "../../theme";
import WizardFooter from "./footer";
import MultiselectAutocomplete from "../multiselect-autocomplete";

const useStyles = makeStyles((th) => ({
  input: {
    borderRadius: 0,
    border: th.borders[1],
    paddingTop: th.spacing(0),
    paddingBottom: th.spacing(0),
    paddingLeft: th.spacing(2),
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    overflow: "hidden",
  },
  row: {
    display: "flex",
    alignItems: "center",
    minHeight: "54px",
    width: "100%",
    borderBottom: th.borders[6],
    "& > div > p": {
      paddingLeft: th.spacing(5),
    },
  },
  checkboxRow: {
    display: "flex",
    alignItems: "center",
    "& p:nth-child(2)": {
      paddingLeft: th.spacing(0),
    },
  },
}));

function StepSetupComponent({
  companies = [],
  assets = [],
  spaces,
  adSpaces,
  values,
  onMultipleSelect,
  onSelectDates,
  onSubmit = () => {},
  unavailableDates,
  onClickDayCalendar,
  minDateCalendar,
  maxDateCalendar,
  onSelectCompany,
  onSelectAsset,
  onSelectSpace,
  spacesStatus,
  companyStatus,
}) {
  const classes = useStyles(theme);

  const [pickSpace, setPickSpace] = useState(!!values.space);
  const [pendingAdSpaces, setPendingAdSpaces] = useState(values.adSpaces);
  const [pickAdvertising, setPickAdvertising] = useState(values.adSpaces?.length > 0);
  const [openAutocompleteAds, setOpenAutocompleteAds] = useState(false);

  const hasSelectedCompanyAndAsset =
    values && values.company && values.asset && Object.keys(values.company).length && Object.keys(values.asset).length;

  const handleOpen = () => {
    setOpenAutocompleteAds(!openAutocompleteAds);
  };

  const handleClearAdSpaces = () => {
    setPendingAdSpaces([]);
    onMultipleSelect("adSpaces")(null, []);
    handleOpen();
  };

  const handleCloseAdSpaces = () => {
    setPendingAdSpaces(values.adSpaces);
    handleOpen();
  };

  const handleAcceptAdSpaces = () => {
    onMultipleSelect("adSpaces")(null, pendingAdSpaces);
    handleOpen();
  };

  const handleChangeAdSpaces = (_, value) => {
    setPendingAdSpaces(value);
  };

  const handleClickPickSpace = (e, checked) => {
    setPickSpace(checked);
    if (!checked) {
      onSelectSpace(undefined);
    }
  };
  const handleClickPickAdSpace = (e, checked) => {
    setPickAdvertising(checked);
    if (!checked) {
      handleOpen();
      setPendingAdSpaces([]);
      onMultipleSelect("adSpaces")(null, []);
    }
  };

  const canSubmit =
    values.company &&
    //  && values.author
    values.asset &&
    (values.space || values.adSpaces?.length > 0) &&
    values.startDate &&
    values.endDate;

  return (
    <ValidatorForm onSubmit={onSubmit}>
      {/** cliente */}
      <Box className={classes.row}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <Trans>Cliente</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {companyStatus.status === "loading" ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Autocomplete
              options={companies}
              getOptionLabel={(option) => option?.name || ""}
              className={classes.searchBox}
              blurOnSelect
              onChange={(event, company) => {
                onSelectCompany(company);
              }}
              value={values.company ? values.company : ""}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.input,
                    style: {
                      color: theme.palette.black,
                      width: "100%",
                      paddingTop: 0,
                    },
                  }}
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
      </Box>
      <Box className={classes.row}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <Trans>Centro comercial</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={assets}
            getOptionLabel={(option) => option.name || ""}
            className={classes.searchBox}
            blurOnSelect
            onChange={(event, asset) => {
              setPendingAdSpaces([]);
              onSelectAsset(asset);
            }}
            value={values?.asset ? values.asset : ""}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputProps={{
                  ...params.InputProps,
                  className: classes.input,
                  style: {
                    color: theme.palette.black,
                    width: "100%",
                    paddingTop: 0,
                  },
                }}
                fullWidth
              />
            )}
          />
        </Grid>
      </Box>
      <Box className={classes.row}>
        <Grid item xs={6} className={classes.checkboxRow}>
          <CheckBox checked={pickSpace} onChange={handleClickPickSpace} disabled={!spaces.length} />
          <Typography variant="body1">
            <Trans>Espacio</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {spacesStatus.status === "loading" ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          ) : hasSelectedCompanyAndAsset && !spaces.length ? (
            <Typography variant="subtitle1">
              <Trans>No hay espacios disponibles</Trans>
            </Typography>
          ) : (
            <Autocomplete
              options={spaces}
              getOptionLabel={(option) => option?.title || ""}
              className={classes.searchBox}
              blurOnSelect
              onChange={(event, sp) => {
                onSelectSpace(sp);
              }}
              disabled={!pickSpace}
              value={values.space ? values.space : ""}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.input,
                    style: {
                      color: theme.palette.black,
                      width: "100%",
                      paddingTop: 0,
                    },
                  }}
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
      </Box>
      <Box className={classes.row}>
        <Grid item xs={6} className={classes.checkboxRow}>
          <CheckBox checked={pickAdvertising} onChange={handleClickPickAdSpace} disabled={!adSpaces.length} />
          <Typography variant="body1">
            <Trans>Espacios publicitarios</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {spacesStatus.status === "loading" ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          ) : hasSelectedCompanyAndAsset && !adSpaces.length ? (
            <Typography variant="subtitle1">
              <Trans>No hay espacios publicitarios disponibles</Trans>
            </Typography>
          ) : (
            <MultiselectAutocomplete
              open={openAutocompleteAds}
              onOpen={handleOpen}
              allOptions={adSpaces}
              pendingValue={pendingAdSpaces}
              onClose={handleCloseAdSpaces}
              onClear={handleClearAdSpaces}
              onAccept={handleAcceptAdSpaces}
              onChange={handleChangeAdSpaces}
              disabled={!pickAdvertising}
            />
          )}
        </Grid>
      </Box>
      {/** dates */}
      <Box className={classes.row}>
        <Grid item xs={6}>
          <Typography variant="body1">
            <Trans>Fechas</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ "margin-top": "0px" }}>
            <CalendarInOutInput
              startDate={values.startDate}
              endDate={values.endDate}
              onSelectDates={onSelectDates}
              unavailableDates={unavailableDates}
              onClickDay={onClickDayCalendar}
              minDate={minDateCalendar}
              maxDate={maxDateCalendar}
              disableCalendar={!values.asset || !(values.space || values.adSpaces?.length > 0)}
            />
          </Box>
        </Grid>
      </Box>
      <WizardFooter step={1} onNext={onSubmit} canSubmit={canSubmit} />
    </ValidatorForm>
  );
}

export default StepSetupComponent;
