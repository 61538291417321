import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import OPTIONS from "./options";
import LogoIcon from "../../icons/logo";

import Link from "../link";
// theme
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  SideMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  menu: {
    flex: 1,
    maxWidth: 240,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  logo: {
    height: 70,
    width: "auto",
    marginTop: theme.spacing(2),
  },
  menuItems: {
    width: 216,
    marginTop: theme.spacing(3),
  },
  item: {
    display: "flex",
    color: "white",
    height: 45,
    width: 216,
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    position: "relative",
    background: "transparent",
    borderBottom: "1px solid white",
    transition: "0.2s background ease-in",
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    "&.active": {
      background: theme.palette.primary.main,
      "& > div": {
        // notificator
        background: theme.palette.white,
      },
    },
  },
  notificator: {
    background: theme.palette.primary.main,
    width: 5,
    height: 5,
    borderRadius: "50%",
    position: "absolute",
    right: 10,
  },
}));

const MenuItemDashboard = ({ active, to, text, icon, hasNews }) => {
  const classes = useStyles(theme);

  return (
    <Link to={to} className={classes.item} partiallyActive activeClassName="active">
      {icon({ style: { width: "40px" } })}
      <Typography variant="body1" style={{ marginLeft: 10 }}>
        {text}
      </Typography>
      {hasNews && <Box className={classes.notificator} />}
    </Link>
  );
};

const SideMenu = ({ bookingConfirmedNews = 0, bookingProgressNews = 0 }) => {
  const classes = useStyles(theme);

  const { i18n } = useLingui();
  const path = "/administrator";

  const hasNewsFor = (key) => {
    switch (key) {
      case "consultas":
        return bookingProgressNews > 0;
      case "reservas":
        return bookingConfirmedNews > 0;
      default:
        return false;
    }
  };

  return (
    <Box className={classes.menu}>
      <Box>
        <LogoIcon className={classes.logo} />
        <Box className={classes.menuItems}>
          {Object.keys(OPTIONS).map((key) => (
            <MenuItemDashboard
              key={key}
              to={`${path}/${OPTIONS[key].pathUrl}`}
              text={i18n._(t`${OPTIONS[key].text}`)}
              icon={OPTIONS[key].icon}
              hasNews={hasNewsFor(key)}
            />
          ))}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="subtitle1" style={{ color: theme.palette.white }}>
          <Trans>powered by</Trans>
        </Typography>
        <a href="https://crenex.io/" target="_blank" rel="noopener noreferrer">
          <Typography
            variant="subtitle1"
            style={{ color: theme.palette.white, fontWeight: "bold", marginLeft: theme.spacing(1 / 2) }}
          >
            <Trans>crenex.io</Trans>
          </Typography>
        </a>
      </Box>
    </Box>
  );
};

export default SideMenu;
