import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { t } from "@lingui/macro";
import BookingInvoicingReportComponent from "../../components/invoicing-report/booking-invoice-report";
import { getBookingInvoicingReportThunk } from "../../redux/actions/invoicing-report-actions";
import { downloadBlobFiles, filterColumnsDisplayTableToString } from "../../utils";
import { downloadBookingInvoicesByUserData } from "../../api/invoicing-report";
import { setDashboardTitle } from "../../redux/actions/misc-actions";

const InvoicingReportContainer = () => {
  const dispatch = useDispatch();

  const bookings = useSelector((state) => state.invoicingReport.bookings.data);
  const pagination = useSelector((state) => state.invoicingReport.bookings.pagination);
  const filters = useSelector((state) => state.invoicingReport.bookingFilters, shallowEqual);
  const status = useSelector((state) => state.invoicingReport.statusBooking);
  const [sort, setSort] = useState({});
  const [columns, setColumns] = useState("");

  useEffect(() => {
    dispatch(setDashboardTitle(`${t`Facturación`} - ${t`Reservas`}`));
  }, []);

  useEffect(() => {
    if (!filters.year) {
      return;
    }
    dispatch(
      getBookingInvoicingReportThunk({
        page: 1,
        sort,
        filters,
      }),
    );
  }, [sort, filters]);

  const handleTableChange = (action, tableState) => {
    const columnsDisplay = filterColumnsDisplayTableToString(tableState);
    setColumns(columnsDisplay);
    switch (action) {
      case "sort":
        localStorage.setItem("bookingInvoicesColumnsSort", JSON.stringify(tableState.sortOrder));
        setSort(tableState.sortOrder);
        break;
      default:
      // console.log("action not handled.");
    }
  };

  const downloadBookingInvocingData = async (format) => {
    const res = await downloadBookingInvoicesByUserData({
      exportFormat: format,
      filters,
      sort,
      columns,
    });
    downloadBlobFiles(res, `Facturacion.${format}`);
  };

  const handleChangePage = (evt, page) => {
    dispatch(
      getBookingInvoicingReportThunk({
        page,
        sort,
        filters,
      }),
    );
  };

  return (
    <BookingInvoicingReportComponent
      bookings={bookings}
      filters={filters}
      status={status}
      pagination={pagination}
      onChangePage={handleChangePage}
      onTableChange={handleTableChange}
      downloadTableData={downloadBookingInvocingData}
    />
  );
};

export default InvoicingReportContainer;
