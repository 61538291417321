import { Box, Button, Hidden, Step, StepConnector, StepLabel, Stepper, Typography } from "@material-ui/core";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "/src/hooks/navigation";
import { Trans, t } from "@lingui/macro";
// steps
import { useLingui } from "@lingui/react";
import AddressStepContainer from "../../containers/listing-form/address-step";
import CalendarStepContainer from "../../containers/listing-form/calendar-step";
import CharacteristicStepContainer from "../../containers/listing-form/characteristics-step";
import DescriptionStepContainer from "../../containers/listing-form/description-step";
import ImagesStepContainer from "../../containers/listing-form/images-step";
import PriceStepContainer from "../../containers/listing-form/price-step";
import TypeStepContainer from "../../containers/listing-form/type-step";
// theme
import DecideDialog from "../decide-dialog";
import theme from "../../theme";
import CustomTabs from "../custom-tabs";

const MyConnector = withStyles({
  active: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.darkGray,
    borderRadius: 1,
  },
})(StepConnector);

const MyStepLabel = withStyles({
  label: {
    color: `${theme.palette.darkGray} !important`,
    textTransform: "uppercase",
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
  completed: {
    color: `${theme.palette.darkGray} !important`,
  },
})(StepLabel);

const useStyles = makeStyles((theme, widthIsNew) => ({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    padding: 0,
    color: theme.palette.black,
  },
  root: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  publishButton: {
    position: "absolute",
    right: 50,
    bottom: 50,
  },
  content: {
    height: "100%",
    paddingTop: theme.spacing(2),
  },
  stepper: {
    width: "100%",
    overflow: "hidden",
  },
  stepperRoot: {
    width: ({ widthIsNew }) => widthIsNew,
    margin: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  stepLabel: {
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "uppercase",
  },
  headerButton: {
    top: theme.spacing(3),
    left: 0,
    whiteSpace: "nowrap",
  },
}));

const TabComponent = ({ spaceId, stepName, setOpen }) => {
  switch (stepName) {
    case "title":
      return <DescriptionStepContainer onSetOpenDelete={setOpen} />;
    case "location":
      return <AddressStepContainer onSetOpenDelete={setOpen} />;
    case "space-type":
      return <TypeStepContainer onSetOpenDelete={setOpen} />;
    case "characteristics":
      return <CharacteristicStepContainer onSetOpenDelete={setOpen} />;
    case "photos":
      return <ImagesStepContainer spaceId={spaceId} onSetOpenDelete={setOpen} />;
    case "price":
      return <PriceStepContainer onSetOpenDelete={setOpen} />;
    case "calendar":
      return <CalendarStepContainer onSetOpenDelete={setOpen} />;
    default:
      return "Unknown step";
  }
};

export default ({ spaceId, spaceTitle, step, deleteSpace, deleteStatus, isNew }) => {
  const widthIsNew = isNew ? "50%" : "100%";
  const classes = useStyles({ ...theme, widthIsNew });
  const { navigate } = useNavigate();
  const [activeStep, setActiveStep] = useState(step);
  const [open, setOpen] = useState(false);

  const { i18n } = useLingui();

  const stepsUrlEdit = ["title", "location", "space-type", "characteristics", "photos", "price", "calendar"];
  const stepsUrlNew = ["title", "location"];
  const STEPS = isNew ? stepsUrlNew : stepsUrlEdit;

  t({ id: "title", message: "Título" });
  t({ id: "location", message: "Ubicación" });
  t({ id: "space-type", message: "Tipo de espacio" });
  t({ id: "characteristics", message: "Características" });
  t({ id: "photos", message: "Fotos" });
  t({ id: "price", message: "Precio" });
  t({ id: "calendar", message: "Calendario" });

  const handleStepClick = (stepTab) => navigate(`/administrator/spaces/${spaceId}/${stepTab}`);

  const handleBack = () => navigate("/administrator/spaces");

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  return (
    <Box className={classes.container}>
      <DecideDialog
        open={open}
        onClose={() => setOpen(false)}
        onAccept={deleteSpace}
        status={deleteStatus}
        onSuccessAccept={handleBack}
        title={<Trans>Eliminar espacio</Trans>}
        questionText={i18n._(t`¿Estás seguro que deseas eliminar el espacio ${spaceTitle}?`)}
        successText={<Trans>El espacio ha sido eliminado con éxito.</Trans>}
        errorText={<Trans>No ha sido posible eliminar el espacio ya que cuenta con reservas en curso.</Trans>}
        isNewStyle
      />
      {!isNew ? (
        <CustomTabs steps={STEPS} activeStep={STEPS[activeStep]} onStepClick={handleStepClick}>
          <TabComponent spaceId={spaceId} stepName={STEPS[activeStep]} setOpen={setOpen} />
        </CustomTabs>
      ) : (
        <>
          <Box display="flex" flexDirection="row" alignItems="flex-start" pl={2} pr={2}>
            <Button
              style={{ transform: "translateY(-3px)" }}
              variant="text"
              onClick={handleBack}
              className={classes.headerButton}
            >
              <BackIcon />
              <Trans>Volver</Trans>
            </Button>
            <Stepper
              connector={<MyConnector />}
              activeStep={activeStep}
              className={classes.stepper}
              classes={{
                root: classes.stepperRoot,
              }}
              alternativeLabel
            >
              {STEPS.map((label, index) => (
                <Step key={label} onClick={() => handleStepClick(index)}>
                  <MyStepLabel>
                    <Hidden smDown>{i18n._(t`${label}`)}</Hidden>
                  </MyStepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box className={classes.content}>
            <TabComponent spaceId={spaceId} stepName={STEPS[activeStep]} setOpen={setOpen} />
          </Box>
        </>
      )}
    </Box>
  );
};
