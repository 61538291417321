import React, { useEffect, useState } from "react";
import InvoicingReportToolbarComponent from "../../components/invoicing-report/invoicing-report-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoicingReportFiltersThunk,
  setInvoicingReportFilters,
} from "../../redux/actions/invoicing-report-actions";
import { getEndOf, getStartOf } from "../../utils/dateUtils";
import { useNavigate } from "/src/hooks/navigation";

const InvoicingReportToolbar = ({ onRefresh }) => {
  const { navigate } = useNavigate();
  const dispatch = useDispatch();

  const [invoicesTotal, setInvoicesTotal] = useState(null);
  const filters = useSelector((state) => state.invoicingReport.filters);
  const filtersOptions = useSelector((state) => state.invoicingReport.filtersOptions);
  const invoices = useSelector((state) => state.invoicingReport.invoices);

  const getInvoicesFilters = () => {
    const assets = JSON.parse(localStorage.getItem("invoiceAssets")) || [];
    const companies = JSON.parse(localStorage.getItem("invoiceClients")) || [];
    const status = JSON.parse(localStorage.getItem("invoiceStatus")) || [];
    const start = getStartOf("year").toDate();
    const end = getEndOf("year").toDate();

    dispatch(setInvoicingReportFilters({ assets, companies, status, start, end }));
  };

  useEffect(() => {
    dispatch(getInvoicingReportFiltersThunk());
    getInvoicesFilters();
  }, []);

  const handleFilterChange = (field) => (newValues) => {
    dispatch(
      setInvoicingReportFilters({
        ...filters,
        [field]: newValues,
      }),
    );
  };

  const handleSearchInvoice = (searchTerm) => {
    dispatch(
      setInvoicingReportFilters({
        ...filters,
        search: searchTerm,
      }),
    );
  };

  const handleFilterDates = ({ start, end }) => {
    dispatch(
      setInvoicingReportFilters({
        ...filters,
        start,
        end,
      }),
    );
  };

  const handleOnChangePage = ({ target }) => {
    if (target.value === "invoice") {
      navigate("/administrator/invoicing");
    } else if (target.value === "booking") {
      navigate("/administrator/invoicing/bookings");
    }
  };

  useEffect(() => {
    setInvoicesTotal(invoices.balance);
  }, [invoices]);

  return (
    <InvoicingReportToolbarComponent
      onRefresh={onRefresh}
      invoicesTotal={invoicesTotal}
      filters={filters}
      filtersOptions={filtersOptions}
      onFilterDatesChange={handleFilterDates}
      onFilterChange={handleFilterChange}
      onChangePage={handleOnChangePage}
      searchInvoice={handleSearchInvoice}
    />
  );
};

export default InvoicingReportToolbar;
