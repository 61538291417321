import { Box, Typography, Tooltip, Hidden, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import numbro from "numbro";
import DateRangeFilter from "../date-range-filter";
import TenantsIcon from "../../icons/sass-icono-assigne";
import MoneyIcon from "../../icons/money-icon";
import UseIcon from "../../icons/use-icon";
// theme
import theme from "../../theme-admin";
import MultiselectSearchFilter from "../multiselect-search-filter";
import Assets from "../../icons/sass-icono-assets-info";
import SearchBoxFilter from "../search-box-filter";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  filters: {
    height: 30,
    "& > .MuiAutocomplete-root": {
      marginRight: theme.spacing(1),
    },
  },
  customInput: {
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
      width: "75px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "5px 5px 5px 0px",
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
      backgroundColor: theme.palette.lighterGray,
      overflowX: "auto",
    },
    input: {
      width: "246px",
    },
    boxClasses: {
      marginLeft: theme.spacing(0),
    },
  },
}));

const InvoicingReportToolbar = ({
  filters,
  onRefresh,
  invoicesTotal,
  filtersOptions,
  onFilterChange = () => {},
  onFilterDatesChange,
  onChangePage,
  searchInvoice = () => {},
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  return (
    <Box display="flex" alignItems="stretch" justifyContent="space-between" width="100%" className={classes.filters}>
      <Box display="flex" alignItems="center" gridGap={3}>
        <DateRangeFilter
          startValues={{
            start: filters.start,
            end: filters.end,
          }}
          title={i18n._(t`Fechas`)}
          onSelect={onFilterDatesChange}
          minDate={null}
        />
        <MultiselectSearchFilter
          title={i18n._(t`Activos`)}
          localStorageName="invoiceAssets"
          icon={() => <Assets className={classes.icon} fill={theme.palette.black} />}
          options={filtersOptions.assets}
          onSelect={onFilterChange("assets")}
        />
        <MultiselectSearchFilter
          title={i18n._(t`Clientes`)}
          localStorageName="invoiceClients"
          icon={() => <TenantsIcon className={classes.icon} fill={theme.palette.black} />}
          options={filtersOptions.companies}
          onSelect={onFilterChange("companies")}
        />
        <MultiselectSearchFilter
          title={i18n._(t`Categoría`)}
          localStorageName="invoiceCategories"
          icon={() => (
            <UseIcon style={{ width: "16px", height: "16px", transform: "scale(1.5)" }} fill={theme.palette.black} />
          )}
          options={filtersOptions.bookingCategories}
          onSelect={onFilterChange("bookingCategories")}
        />
        <Box position="relative" display="flex" flexDirection="row">
          <SearchBoxFilter
            placeholder={i18n._(t`Buscar`)}
            onSearch={searchInvoice}
            inputClasses={classes.input}
            boxClasses={`${classes.boxClasses}`}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {(invoicesTotal || invoicesTotal === 0) && (
          <Tooltip title={i18n._(t`Total facturado`)}>
            <Box display="flex" alignItems="center">
              <MoneyIcon fill={theme.palette.primary.main} style={{ width: 36, height: 36 }} />
              <Hidden lgDown>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  <Trans>Total facturado:</Trans>
                </Typography>
              </Hidden>
              <Box ml={1 / 2} display="flex" alignItems="center">
                <Typography variant="body1" style={{ fontWeight: "bold" }} color="primary">
                  {numbro(parseFloat(invoicesTotal)).formatCurrency()}
                </Typography>
              </Box>
              <Box ml={3 / 2} mr={3 / 2} style={{ height: "36px", width: "1px", borderRight: theme.borders[6] }} />
            </Box>
          </Tooltip>
        )}
        <Select
          value="invoice"
          onChange={onChangePage}
          variant="outlined"
          style={{ minWidth: "150px", maxWidth: "150px", height: "36px" }}
        >
          <MenuItem value="invoice">{i18n._(t`Vista por facturación`)}</MenuItem>
          <MenuItem value="booking">{i18n._(t`Vista por reserva`)}</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default InvoicingReportToolbar;
