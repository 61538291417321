import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Popover, Typography } from "@material-ui/core";
import BackLongIcon from "../../icons/back-long-icon";
import Calendar from "../calendar";
import CalendarIcon from "../../icons/calendar-icon";
import { add, dateDiff, formatDateUtc, isDateValid } from "../../utils/dateUtils";
import theme from "../../theme-admin";

const useStyles = makeStyles((theme) => ({
  calendarBox: {
    width: "100%",
    height: "300px",
    border: theme.borders[2],
  },
  datesBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 33,
    border: theme.borders[1],
    cursor: "pointer",
    "&.disabled": {
      cursor: "not-allowed",
      opacity: 0.5,
      pointerEvents: "none",
    },
  },
  dateBoxIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    height: "100%",
    borderRight: theme.borders[1],
  },
  rightArrow: {
    transform: "rotate(180deg)",
    width: 21,
    height: 20,
    display: "flex",
    alignItems: "center",
  },
  popper: {
    borderRadius: 3,
    zIndex: 130,
    width: 750,
    padding: theme.spacing(3),
    "& .MuiAutocomplete-listbox": {
      maxHeight: 200,
    },
  },
}));

const CalendarInOutInput = ({
  startDate,
  endDate,
  onSelectDates,
  unavailableDates = {},
  onClickDay,
  minDate,
  maxDate,
  disableCalendar,
}) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const ref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingDates, setPendingDates] = useState([startDate, endDate]);

  const handleIsTileDisabled = ({ date }) => {
    const formattedDate = formatDateUtc(date, "YYYY-MM-DD");
    return unavailableDates[formattedDate] !== undefined;
  };

  const handleClick = (event) => {
    setAnchorEl(ref.current);
    document.addEventListener("mousedown", mouseDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
    document.removeEventListener("mousedown", mouseDown);
  };

  const mouseDown = (e) => {
    if (!ref.current?.contains(e.target)) {
      //  handleClose();
    }
  };

  /** calendar handlers */
  const handleClearDates = () => {
    setPendingDates([null, null]);
    onSelectDates([null, null]);
  };

  const areUnavailableDatesWithin = (start, end, unavailableDates) => {
    const diff = dateDiff(start, end);
    for (let i = 0; i <= diff; i++) {
      const date = add(start, i, "day").format("YYYY-MM-DD");
      if (unavailableDates[date]) {
        return true;
      }
    }
    return false;
  };

  const handleAcceptDates = () => {
    onSelectDates(pendingDates);
    handleClose();
  };

  const handleChangeDates = (dates) => {
    // we need to check if there are unavailable dates within it
    if (areUnavailableDatesWithin(dates[0], dates[1], unavailableDates)) return;

    setPendingDates(dates);
  };

  useEffect(() => {
    setPendingDates([startDate, endDate]);
  }, [startDate, endDate]);

  return (
    <>
      <Popover
        id="in-out-calendar"
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        placement="bottom-top"
        className={classes.popper}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <Box p={1}>
          <Calendar
            startDate={pendingDates[0]}
            endDate={pendingDates[1]}
            tileDisabled={handleIsTileDisabled}
            onChange={handleChangeDates}
            onClickDay={onClickDay}
            minDate={minDate}
            maxDate={maxDate}
            isDouble
            selectRange
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={3}
            p={1}
            style={{ backgroundColor: theme.palette.white }}
          >
            <Button color="default" onClick={handleClearDates}>
              <Trans>Limpiar</Trans>
            </Button>
            <Button variant="contained" color="default" onClick={handleClose}>
              <Trans>Cancelar</Trans>
            </Button>
            <Button variant="contained" color="primary" onClick={handleAcceptDates}>
              <Trans>Aceptar</Trans>
            </Button>
          </Box>
        </Box>
      </Popover>
      <Box ref={ref} className={`${classes.datesBox} ${disableCalendar ? "disabled" : ""}`} onClick={handleClick}>
        <Box className={classes.dateBoxIcon}>
          <CalendarIcon style={{ width: 25, height: 25 }} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          width="100%"
          style={{ textTransform: "uppercase" }}
        >
          <Typography variant="subtitle1">
            {`${isDateValid(startDate) ? formatDateUtc(startDate, "DD/MM/YYYY") : i18n._(t`Ingreso`)}`}
          </Typography>

          <Box ml={1} mr={1}>
            <BackLongIcon className={classes.rightArrow} />
          </Box>
          <Typography variant="subtitle1">
            {`${isDateValid(endDate) ? formatDateUtc(endDate, "DD/MM/YYYY") : i18n._(t`Salida`)}`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CalendarInOutInput;
